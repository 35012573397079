<template>
  <div>
    <v-menu
      bottom
      left
      min-width="200"
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn text v-bind="attrs" v-on="on" class="mt-15">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>
      <v-card class="mx-auto" width="256" tile>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                {{ me.name }}
              </v-list-item-title>
              <v-list-item-subtitle class="h5">
                {{ me.profile }}</v-list-item-subtitle
              >
              <v-list-item-action-text>{{ me.email }}</v-list-item-action-text>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list nav dense>
          <v-list-item-group v-model="selectedItem" color="primary">
            <v-list-item v-for="(item, i) in items" :key="i" @click="click(i)">
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-menu>
    <pswform
      :me="me"
      :showform="isPswOpen"
      @close="closePswForm"
      :key="kpsw"
      @pwd_changed="isPswOpen = false"
    >
    </pswform>

    <filelist
      :showform="!isDocListClose"
      :key="fdocl"
      @close="isDocListClose = true"
      :editer="true"
      :doc_type="-1"
      :title="'My Documents'"
    >
    </filelist>
  </div>
</template>

<script>
export default {
  name: "DefaultAccount",
  components: {
    pswform: () => import("@/components/PasswordForm.vue"),
    filelist: () => import("@/components/FileList.vue"),
  },
  data: () => ({
    isDocListClose: true,
    fdocl: 1000,
    isPswOpen: false,
    kpsw: 0,
    me: {},
    selectedItem: 0,
    items: [
      { text: "My documents", icon: "mdi-folder" },
      { text: "Password Change", icon: "mdi-key-change" },
      { text: "Settings", icon: "mdi-account-multiple" },
      { text: "Options", icon: "mdi-star" },
    ],
  }),
  computed: {},
  created() {
    if (this.$store.state) this.me = this.$store.state.me;
  },
  methods: {
    async click(i) {
      if (i == 0) {
        this.isDocListClose = false;
        this.fdocl++;
      }
      if (i == 1) {
        this.isPswOpen = true;
        this.kpsw++;
      }
    },
    async requette(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.req = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.progress = false;
        });
      return r;
    },
    closePswForm() {
      this.isPswOpen = false;
    },
  },
};
</script>
